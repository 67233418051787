<template>
  <div class="player-list">
    <div class="nav-bar">
      <div class="nav">
        <h2 class="title">推荐课程</h2>
      </div>
    </div>
    <div class="list" :style="{ height: `calc(${playVideoBoxHeight}px - 39px)` }">
      <div class="list-item" v-for="(item, index) in AiCourseList" :key="index" @click="playCourse(item)">
        <div :class="'index ' + (courseId == item.courseId ? 'active' : '')">
          {{ index + 1 > 9 ? index + 1 : "0" + (index + 1) }}
        </div>
        <div :class="'title ' + (courseId == item.courseId ? 'active' : '')">
          {{ item.courseName }}
        </div>
        <div :class="'time ' + (courseId == item.courseId ? 'active' : '')">
          {{item.watchCount}} <img src="@assets/images/watch.png" alt="watch" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { aiRecommendList } from "@api/discovery/index";
export default {
  props: [
    'playVideoBoxHeight'
  ],
  data() {
    return {
      AiCourseList: [],
      pageVisiable: false,
    };
  },
  components: {},
  computed: {
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  activated() {
    // this.AiCourseList = JSON.parse(window.sessionStorage.getItem("courseList"));
    this.getAiCourse();
  },
  // destroyed(){
  //   window.sessionStorage.removeItem("courseList")
  // },
  methods: {
    // 获取套餐数据
    getAiCourse() {
      this.courseId = window.sessionStorage.getItem("courseId");
      aiRecommendList().then(({ data }) => {
        if (data.data && data.success === true) {
          this.AiCourseList = data.data.rows.splice(0,6);
        } else {
          this.AiCourseList = [];
          console.log(data.message);
        }
      });
    },
    // 选中播放
    playCourse(data) {
      this.courseId = data.courseId;
      window.sessionStorage.setItem("courseId", data.courseId);
    },
  },
};
</script>
<style lang="scss" scoped>
.player-list {
  padding: 0 10px;
  .nav-bar {
    margin-top: -6px;
    .nav {
      color: $--title-color;
      .title {
        display: inline-block;
        color: inherit;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        border-bottom: 3px solid #4a4a4a;
        margin-bottom: 0;
      }
    }
  }
  .list {
    overflow-x: hidden;
    .list-item {
      display: flex;
      justify-content: space-between;
      color: #4a4a4a;
      font-family: PingFangSC-Light, PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      margin-top: 40px;
      cursor: pointer;
      &:nth-of-type(1){
        margin-top: 34px;
      }
      .index {
        padding-left: 7px;
        color: inherit;
      }
      .title {
        color: inherit;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        padding: 0 40px;
        flex-grow: 1;
      }
      .time {
        font-size: 14px;
        font-weight: 300;
        color: inherit;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .active {
        color: #4a9afa;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .player-list {
    .list {
      .list-item {
        margin-top: 15px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .player-list {
    padding: 0 10px;
    .nav-bar {
      margin-top: 20px;
    }
    .list {
      height: 200px!important;
    }
  }
}
</style>
