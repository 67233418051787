<template>
  <div class="apidoc-body">
    <a-row type="flex" justify="space-between">
      <a-col :xs="24" :md="5">
        <div class="left-content" :class="{'left-content-collapsed': collapsed}">
          <a-button v-if="collapsed" class="left-content-toggle" type="primary" @click="toggleCollapsed">
            <a-icon type="menu-unfold" />
          </a-button>
          <div class="left-content-menu" v-if="dataList.length > 0">
            <a-button v-if="!collapsed" class="left-content-close" type="primary" @click="toggleCollapsed">
              <a-icon type="close" />
            </a-button>
            <a-menu
              v-show="!collapsed"
              class="left-content-menu-list"
              :default-selected-keys="selectedKeys"
              :default-open-keys="openKeys"
              mode="inline"
            >
              <template v-for="item in dataList">
                <a-menu-item v-if="item.childList.length == 0" :key="item.resourceId" @click="menuChange(item.remark)">
                  <span>{{ item.resourceName }}</span>
                </a-menu-item>
                <a-sub-menu v-else :key="item.resourceId">
                  <span slot="title">{{ item.resourceName }}</span>
                  <a-menu-item v-for="subItem in item.childList" :key="subItem.resourceId" @click="menuChange(subItem.remark)">
                    <span>{{ subItem.resourceName }}</span>
                  </a-menu-item>
                </a-sub-menu>
              </template>
            </a-menu>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="19">
        <div class="right-content" v-html="apidocDetail"></div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { apidocList } from "@api/apidoc/index";
export default {
  metaInfo: {
    title: "API文档 - 睿识课",
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是上海睿识课企业发展有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  data() {
    return {
      dataList: [],
      apidocDetail: '',
      selectedKeys: [],
      openKeys: [],
      collapsed: false
    };
  },
  activated() {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.getDataList();
    this.changeLeftMenu();
  },
  mounted () {
    window.addEventListener('resize', this.changeLeftMenu, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.changeLeftMenu);
  },
  methods: {
    // 获取数据列表
    getDataList () {
      apidocList().then(({ data }) => {
        if (data && data.success === true) {
          this.dataList = data.data;
          this.selectedKeys = [this.dataList[0].childList.length > 0 ? this.dataList[0].childList[0].resourceId : this.dataList[0].resourceId];
          this.apidocDetail = this.dataList[0].childList.length > 0 ? this.dataList[0].childList[0].remark : this.dataList[0].remark;
          this.openKeys = [this.dataList[0].childList.length > 0 ? this.dataList[0].resourceId : ''];
        } else {
          this.dataList = [];
        }
      });
    },
    // 监听宽度,判断左侧列表样式
    changeLeftMenu () {
      if (window.innerWidth > 767) {
        this.collapsed = false
      } else {
        this.collapsed = true
      }
      this.$root.$emit('visibleMask', false)
    },
    // 左侧菜单展示/收起
    toggleCollapsed () {
      this.$root.$emit('visibleMask', this.collapsed)
      this.collapsed = !this.collapsed;
    },
    // 点击菜单
    menuChange (remark) {
      this.apidocDetail = remark
    }
  }
};
</script>
<style lang="scss" scoped>
.apidoc-body {
  width: 100%;
  .left-content {
    .left-content-toggle,
    .left-content-close {
      display: none;
    }
    .left-content-toggle {
      padding: 0;
      width: 36px;
      position: absolute;
      left: 0;
      z-index: 2;
      border-radius: 0 5px 5px 0;
    }
    .left-content-menu {
      position: relative;
      height: 100%;
      z-index: 1;
    }
    .left-content-close {
      padding: 0;
      width: 36px;
      position: absolute;
      right: -36px;
      top: 6px;
      border-radius: 0 5px 5px 0;
    }
    .left-content-menu-list {
      background: #fff;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      border: none;
    }
  }

  .right-content {
    padding: 30px 15px 0 15px;
    min-height: calc(100vh - 70px - 124px);
    border-left: 1px solid #e8e8e8;
  }
}
@media only screen and (max-width: 767px) {
  .apidoc-body {
    .left-content {
      position: fixed;
      z-index: 1001;
      width: 40vw;
      height: 100vh;
      top: 0;
      &-collapsed {
        top: 80px;
      }
      .left-content-toggle,
      .left-content-close {
        display: block;
      }
      .left-content-menu-list {
        border-right: 1px solid #e8e8e8;
      }
    }
    .right-content {
      padding-top: 45px;
      border: none;
    }
  }
}
</style>
