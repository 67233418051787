<template>
  <div class="products-body">
    <div class="banner">
      <a-row type="flex" justify="center">
        <a-col :xs="22" :sm="11" :lg="{ span: 9, offset: 3 }">
          <div class="text-info">
            <h2>关于我们</h2>
            <p>ABOUT US</p>
            <span class="text-btn">与安全同行</span>
          </div>
        </a-col>
        <a-col :xs="22" :sm="11" :lg="10">
          <div class="text-img">
            <img src="@assets/images/about/gywm_banner.png" alt />
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="first">
      <a-row type="flex" justify="center" align="middle">
        <a-col :xs="22" :sm="11" :lg="9">
          <div class="text-img">
            <img src="@assets/images/about/gywm_gsxx.png" alt />
          </div>
        </a-col>
        <a-col type="flex" :xs="22" :sm="{ span: 10, offset: 1 }" :lg="{ span: 11, offset: 1 }">
          <div class="text-info">
            <div class="title">
              <span class="title-font">公司信息</span>
            </div>
            <div
              class="text-content"
            >上海睿识课企业发展有限公司成立于2019年05月。目前运营总部在北京朝阳区,公司主营业务包括在线安全科普、数据分析、汽车科技与相关增值服务等等。主要的产品有“睿识课教育”在线平台等。公司致力于打造结合“安全科普教育”和“安全数据分析”于一体的高科技数据系统，为C端客户和合作方带来实用、精准的风险控制综合服务。</div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="second">
      <a-row type="flex" justify="center" align="middle">
        <a-col
          :xs="{span: 22, order: 1}"
          :sm="{span: 11, order: 0}"
          :lg="{span: 11, order: 0}"
        >
          <div class="text-info">
            <div class="title">
              <span class="title-font">产品信息</span>
            </div>
            <div class="text-content">
              睿识课（RSK）是上海睿识课企业发展有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。
              睿识课教育平台为用户提供场景化、实用性强的安全科普课程，以预防在不同场所、不同环境下发生灾害。用户可以根据自己的实际情况选择课程组合，也可以自选课程。课程内容涵盖交通安全、医疗急救、灾害自救、防范暴力恐怖犯罪、日常意外处理等场景。课程形式以音频和视频为主，每节课以一个知识点为中心，在较短的时间内帮助用户掌握关键的安全知识，方便用户利用工作生活的碎片时间更高效地学习。从而不断增强用户的安全意识，提高自我保护能力。
            </div>
          </div>
        </a-col>
        <a-col
          :xs="{span: 22, offset: 0, order: 0}"
          :sm="{ span: 10, offset: 1, order: 1 }"
          :lg="{ span: 9, offset: 1, order: 1 }"
        >
          <div class="text-img">
            <img src="@assets/images/about/gywm_cpxx.png" alt />
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- <share></share> -->
    <back-top></back-top>
  </div>
</template>
<script>
// import share from '../share'
import backTop from '../../components/backTop'
export default {
  metaInfo: {
    title: `关于我们 - 睿识课`,
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是上海睿识课企业发展有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  components: {
    // share
    backTop
  }
};
</script>
<style lang="scss" scoped>
.products-body {
  max-width: 1230px;
  margin: 0 auto;
  overflow: hidden;
  .banner {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    height: 526px;
    background: linear-gradient(
      180deg,
      rgba(55, 145, 252, 1) 0%,
      rgba(152, 199, 255, 1) 100%
    );
    color: #ffffff;
    .text-info {
      flex: 1;
      h2 {
        margin: 149px 0 0 0;
        color: inherit;
        font-size: 40px;
      }
      p {
        max-width: 510px;
        margin: 10px 0 40px 0;
        color: inherit;
        font-size: 28px;
        letter-spacing: 10px;
      }
      .text-btn {
        background: rgba(255, 255, 255, 1);
        margin: 40px 0 0 0;
        color: #4a9afa;
        font-size: 28px;
        font-weight: 500;
        line-height: 52px;
        text-align: center;
        letter-spacing: 10px;
        padding: 8px 9px 7px;
      }
    }
    .text-img {
      flex: 1;
      position: relative;
      img {
        position: absolute;
        width: 556px;
        height: 397px;
        top: 80px;
      }
    }
  }
  .first,
  .second {
    display: flex;
    flex-direction: row;
    .text-img {
      text-align: center;
      img {
        width: 100%;
        max-width: 410px;
      }
    }
    .text-info {
      div {
        flex: 1;
      }
    }
  }
  .first {
    margin-top: 692px;
  }
  .second {
    margin: 114px 0 102px 0;
    .text-info {
      .title{
          text-align: right;
          .title-font{
            margin-right: 0;
          }
      }
    }
  }
  .title-font {
    color: #4A4A4A;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    margin-right: 20px;
  }
  .title-font-family {
    font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
  }
  .text-content {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
    line-height: 30px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1230px) {
  .products-body {
    .banner {
      position: static;
    }
    .first {
      margin-top: 112px;
    }
  }
}
@media only screen and (max-width: 576px) {
  .products-body {
    .banner {
      .text-img {
        display: none;
      }
    }
    .first,
    .second {
      .text-info {
        margin-top: 20px;
      }
    }
  }
}
</style>
