<template>
  <div class="search-body">
    <div class="nav-bar">
      <div class="nav">
        <h3>搜索结果</h3>
        <span v-for="(tab,index) in tabs" @click="toggle(index,tab.view)" :key="index">
          <a :class="{active:active==index}">{{tab.name}}</a>
        </span>
      </div>
    </div>
    <component :is="currentView" ref="tabView"></component>
  </div>
</template>
<script>
import courseTab from "./base/course";
import setMealTab from "./base/setMeal";
export default {
  metaInfo: {
    title: "搜索 - 睿识课",
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是上海睿识课企业发展有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  data() {
    return {
      active: 0,
      currentView: "courseTab",
      tabs: [
        {
          name: "课程",
          view: "courseTab"
        },
        {
          name: "套餐",
          view: "setMealTab"
        }
      ]
    };
  },
  components: {
    courseTab,
    setMealTab
  },
  methods:{
    toggle(i,v){
      this.active=i;
      this.currentView=v;
    },
  }
};
</script>
<style lang="scss" scoped>
.nav-bar {
  max-width: 1230px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 15px;
  .nav {
    display: flex;
    align-content: center;
    color: $--title-color;
    h3 {
      color: inherit;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
    span {
      padding-top: 5px;
      margin-left: 50px;
      a {
        color: inherit;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(74, 74, 74, 1);
      }
      .active {
        border-bottom: 2px solid $--title-color;
      }
    }
  }
}
@media only screen and (max-width: 1230px) {
  .search-body {
    padding: 0 10px;
  }
}
</style>
